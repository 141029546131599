import axios from "axios";
import { toast } from "react-toastify";
var qs = require("qs");

const apiGetStats = async (postData) => {
  const url = `/summary`;

  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      //   toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    // toast.error("An error occoured. Please try again.");
  }
};

const apiGetCustomerFormFields = async (postData) => {
  const url = `/register/customer_fields`;
  try {
    const res = await axios.post(url, qs.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiRegisterCustomer = async (postData) => {
  console.log(postData);
  const url = `/register/customer`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetCustomers = async (postData) => {
  const url = `/all-customers`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetCustomersListCart = async (postData) => {
  const url = `/all-cart-customers`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetCustomerDetails = async (postData) => {
  const url = `/get-customer-profile`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiEditCustomer = async (postData) => {
  const url = `/update-customer-profile`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again.");
  }
};

const apiGetMyBookings = async (postData) => {
  const url = `/bookings`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    toast.error("An error occoured. Please try again.");
  }
};

// const apiDownloadTicket = async (postData) => {
//   const url = `/btob-tickets`;
//   try {
//     const res = await axios.post(url, JSON.stringify(postData), {
//       responseType: "blob",
//     });
//     if (res?.status === 200) {
//       const href = window.URL.createObjectURL(res.data);

//       const anchorElement = document.createElement("a");

//       anchorElement.href = href;
//       anchorElement.download = `TM_${postData.booking_id}_ticket.pdf`;

//       document.body.appendChild(anchorElement);
//       anchorElement.click();

//       document.body.removeChild(anchorElement);
//       window.URL.revokeObjectURL(href);
//     } else {
//       toast.error("An error occoured. Please try again");
//     }
//   } catch (error) {
//     console.log(error);
//     toast.error("An error occoured. Please try again..");
//   }
// };
const apiDownloadTicket = async (postData) => {
  const url = `/btob-tickets`;
  try {
    const res = await axios.post(url, JSON.stringify(postData));
    console.log(res);
    if (res?.status === 200 && res?.data.success === true) {
      const uri = res?.data?.path;

      const fileName = `TM_${postData.booking_id}_ticket.pdf`;

      var link = document.createElement("a");
      link.download = fileName;
      link.target = "_blank";
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("An error occoured. Please try again");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occoured. Please try again..");
  }
};
export {
  apiGetStats,
  apiRegisterCustomer,
  apiGetCustomerFormFields,
  apiGetCustomers,
  apiGetCustomersListCart,
  apiGetMyBookings,
  apiGetCustomerDetails,
  apiEditCustomer,
  apiDownloadTicket,
};
