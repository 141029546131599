import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import config from "config/config";
import { apiRegisterCustomer } from "apis/b2b";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TicketsLoader from "components/TicketsLoader/TicketsLoader";
import RecursiveContainer from "components/DynamicInputs/RecursiveContainer";
import { toast } from "react-toastify";
import tempFields from "./addCustomer.json";

function B2BModalAddCustomer({ addBtnRef, actionPerformed }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [showPopUp, setshowPopUp] = useState(false);

  const [fieldsRequired, setFieldsRequired] = useState(null);

  const clospopup = () => {
    setshowPopUp(false);
  };
  const openpopup = () => {
    setshowPopUp(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = {};

    for (let [key, prop] of formData) {
      data[`${key}`] = prop;
    }

    data.token = user.jwt;

    const res = await apiRegisterCustomer(data);

    if (res.success && res.success === true) {
      clospopup();
      actionPerformed();
    } else {
      const errors = res.message;

      for (const [key, value] of Object.entries(errors)) {
        toast.error(`${key}: ${value}`);
      }
      // toast.error(res.message ?? "Error occoured.");
    }
  };

  useEffect(() => {
    addBtnRef?.current?.addEventListener("click", openpopup, false);

    if (fieldsRequired === null) {
      setFieldsRequired(tempFields);
    }

    return () => {
      addBtnRef?.current?.addEventListener("click", openpopup, false);
    };
  }, []);

  return (
    <>
      {showPopUp && (
        <div
          tabIndex="-1"
          className="fixed flex justify-center items-center top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full backdrop-blur-sm bg-white/30"
        >
          <div className="relative w-full max-w-2xl	max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between rounded-t">
                <button
                  onClick={() => clospopup()}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="medium-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <h3 className="px-6 mb-3 text-lg font-medium">Add Customer</h3>
              <div
                className="relative p-6 flex-auto"
                style={{ height: "70vh", overflowY: "scroll" }}
              >
                {fieldsRequired === null ? (
                  <TicketsLoader />
                ) : (
                  <form onSubmit={handleFormSubmit}>
                    <RecursiveContainer config={fieldsRequired.fields} />
                    <br />
                    <div className="flex justify-center">
                      <ButtonPrimary className="w-1/2" type="submit">
                        Save
                      </ButtonPrimary>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default B2BModalAddCustomer;
