import React from 'react'
import './index.css';

function TicketsLoader() {
  return (
    <div className="flex justify-center items-center p-20">
        <span className="tickets-loader"></span>
    </div>
  )
}

export default TicketsLoader