import { useMemo } from "react";

import CustomersTable from "./CustomersTable";

const TMCustomersPage = ({ className = "" }) => {
  const rederPageHeader = useMemo(() => {
    return (
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <div className="flex items-center gap-x-3">
            <h2 className="text-lg font-medium text-gray-800 dark:text-white">
              All Customers
            </h2>
          </div>
        </div>
      </div>
    );
  }, []);
  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <div className="container px-5 md:px-10 mx-auto mt-12 relative space-y-8 mb-20 lg:space-y-12 lg:mb-12">
        <section className="w-100">
          {rederPageHeader}
          <CustomersTable />
        </section>
      </div>
    </div>
  );
};

export default TMCustomersPage;
