import {
  ShoppingCartIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import i18n from "i18n/i18n";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import MenuBar from "shared/MenuBar/MenuBar";
// import Search from "shared/MobileSearch/MobileSearch"
// import isInViewport from "utils/isInViewport";

// let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
  id?: any;
}

const NAV_NONLOG: NavItem[] = [
  {
    name: i18n.t("NaveMobileMenuHome"),
    link: "/",
    icon: HomeIcon,
  },
  // {
  //   name: i18n.t('NaveMobileMenuSearch'),
  //   link: "/search",
  //   icon: MagnifyingGlassIcon
  // },
  {
    name: i18n.t("NaveMobileMenuCart"),
    link: "/cart",
    id: "cart",
    icon: ShoppingCartIcon,
  },
  // {
  //   name: i18n.t('NaveMobileMenuLogin'),
  //   link: "/login",
  //   icon: UserCircleIcon,
  // },
  {
    name: i18n.t("NaveMobileMenuBar"),
    icon: MenuBar,
  },
];

const NAV_LOG: NavItem[] = [
  {
    name: i18n.t("NaveMobileMenuHome"),
    link: "/",
    icon: HomeIcon,
  },
  // {
  //   name: i18n.t("NaveMobileMenuSearch"),
  //   link: "/search",
  //   icon: MagnifyingGlassIcon,
  // },
  {
    name: i18n.t("NaveMobileMenuCart"),
    link: "/cart",
    id: "cart",
    icon: ShoppingCartIcon,
  },
  // {
  //   name: i18n.t("NaveMobileMenuProfile"),
  //   link: "/profile",
  //   icon: UserCircleIcon,
  // },
  {
    name: i18n.t("NaveMobileMenuBar"),
    icon: MenuBar,
  },
];
const FooterNav = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cart = useSelector((state: any) => state.cart);

  const { isLoggedIn } = useSelector((state: any) => state.user);
  const location = useLocation();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleEvent);
  // }, []);

  const NAV = isLoggedIn ? NAV_LOG : NAV_NONLOG;

  // const handleEvent = () => {
  //   window.requestAnimationFrame(showHideHeaderMenu);
  // };

  // const showHideHeaderMenu = () => {
  //   let currentScrollPos = window.pageYOffset;
  //   if (!containerRef.current) return;

  //   // SHOW _ HIDE MAIN MENU
  //   if (currentScrollPos > WIN_PREV_POSITION) {
  //     if (
  //       isInViewport(containerRef.current) &&
  //       currentScrollPos - WIN_PREV_POSITION < 80
  //     ) {
  //       return;
  //     }

  //     containerRef.current.classList.add("FooterNav--hide");
  //   } else {
  //     if (
  //       !isInViewport(containerRef.current) &&
  //       WIN_PREV_POSITION - currentScrollPos < 80
  //     ) {
  //       return;
  //     }
  //     containerRef.current.classList.remove("FooterNav--hide");
  //   }

  //   WIN_PREV_POSITION = currentScrollPos;
  // };

  return (
    <div
      // ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {/* MENU */}
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <div className="relative inline-flex items-center" key={index}>
              <Link
                to={item.link}
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                  active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
              >
                <item.icon
                  className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
                />
                {item.id === "cart" && cart?.tickets?.length > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-green bg-yellow-400 border-2 border-blue rounded-full -top-2 -right-2 dark:border-gray-900 dark:text-white">
                    {cart?.tickets.reduce(
                      (partialSum: any, e: { tickets: any[] }) =>
                        partialSum +
                        e.tickets.reduce(
                          (totalTickets, a) => totalTickets + a.totals,
                          0
                        ),
                      0
                    )}
                  </div>
                )}
                <span className="text-[11px] leading-none mt-1">
                  {item.name}
                </span>
              </Link>
            </div>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
