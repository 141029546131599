// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import config from 'config/config';
const {region} = config;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigUae = {
  apiKey: "AIzaSyCA2QTOFD2UfI6OESCpuMHCrxxTG_aJa20",
  authDomain: "tm-dubai.firebaseapp.com",
  projectId: "tm-dubai",
  storageBucket: "tm-dubai.appspot.com",
  messagingSenderId: "759321828350",
  appId: "1:759321828350:web:e1a7edf38ae9221b977eaf",
  measurementId: "G-FEVDY8HKFE",
  vapid: 'BKNd6ru7jT7WipjomoY1W0UjTlFCFVtHBookJ_gGrecJjzDpT4avn8JmW6tf67HzJk66ZeOnxXJh-zZ0cwzBKq0'
};

const firebaseConfigSaudi = {
  apiKey: "AIzaSyAXX_ad11o-lvGt9_QUasbdKQNBkxZ1gHE",
  authDomain: "tm-saudi-b6543.firebaseapp.com",
  projectId: "tm-saudi-b6543",
  storageBucket: "tm-saudi-b6543.appspot.com",
  messagingSenderId: "720807448445",
  appId: "1:720807448445:web:21753cbf9efe73a9a7c614",
  measurementId: "G-3PXTHG97J0",
  vapid: 'BFIw5ksY6eRDFz3OQ2S88fmgfS9gqrmgR2ktb0x84wyhLfu_AX9Ce27u3I_AAyl3utsqOeBwArICEeGjgCWxjm4'
};

const firebaseConfigCanada = {
  apiKey: "AIzaSyAXX_ad11o-lvGt9_QUasbdKQNBkxZ1gHE",
  authDomain: "tm-saudi-b6543.firebaseapp.com",
  projectId: "tm-saudi-b6543",
  storageBucket: "tm-saudi-b6543.appspot.com",
  messagingSenderId: "720807448445",
  appId: "1:720807448445:web:21753cbf9efe73a9a7c614",
  measurementId: "G-3PXTHG97J0",
  vapid: 'BFIw5ksY6eRDFz3OQ2S88fmgfS9gqrmgR2ktb0x84wyhLfu_AX9Ce27u3I_AAyl3utsqOeBwArICEeGjgCWxjm4'
};

const firebaseConfig = region === 'uae' ? firebaseConfigUae 
                : region === 'saudi' ? firebaseConfigSaudi
                :firebaseConfigCanada;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
// console.log(firebaseApp);

let firebaseMessaging = null;

if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
   firebaseMessaging = getMessaging(firebaseApp);
}


export const getPushToken = (setTokenFound) => {
  if(firebaseMessaging)
  return getToken(firebaseMessaging, {vapidKey: firebaseConfig.vapid}).then((currentToken) => {
    
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {

    onMessage(firebaseMessaging, (payload) => {
      resolve(payload);
    });
});