/* eslint-disable no-throw-literal */
import axios from "axios";
import {
  setCategories,
  setMenuAttractions,
  setHomeBanners,
} from "store/reducers/masterData";
//ADMIN APIS ACTION
const apiGetCategories = async (dispatch) => {
  const url = `/categories`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      const resData = res?.data;
      if (dispatch) dispatch(setCategories({ categories: resData }));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetCategoriesTabs = async (dispatch) => {
  const url = `/home-page-cat`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      // dispatch(setCategories( {categories: resData}));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetAttractionEvents = async (page = 0, token) => {
  const url = `/all-attractions`;
  try {
    const res = await axios.post(url, JSON.stringify({ token: token }));

    if (res.status === 200) {
      // dispatch(setCategories( {categories: resData}));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetEventDetails = async (id, token) => {
  const url = `/attraction/details`;
  try {
    const res = await axios.post(url, JSON.stringify({ id, token }));

    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// const apiGetPrioSlots = async (eventId, totalTickets, date) => {
//   const url = `/attraction/prio-tickets/${eventId}/get-time-slots/${date}/${totalTickets}/`;
//   try {
//     const res = await axios.get(url);

//     if (res.status === 200) {
//       return res?.data;
//     } else {
//       throw "Error Occoured";
//     }
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// };

const apiGetPrioSlots = async (eventId, totalTickets, date) => {
  const url = `/prio-tickets/get-time-slots`;
  try {
    // const res = await axios.post(url, {event_id:eventId, date:date, count: totalTickets});
    const res = await axios.post(
      url,
      JSON.stringify({
        event_id: eventId,
        ticket_date: date,
        ticket_count: totalTickets,
      })
    );

    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const formatMenuAttractions = (rawData) => {
  return rawData.map((el) => {
    return {
      id: el?.id,
      href: `/category/${el.href || el.id}`,
      image: `https://portal.ticketmagic.me/uploads/banners/${el?.thumbnail}`,
      title: el?.category_name,
      items: el?.event_list.slice(0, 6).map((ev) => {
        return {
          id: ev.event_id,
          name: ev.title,
          href: `/attraction/${ev.href}`,
        };
      }),
    };
  });
};

const apiGetMenuAttractions = async (dispatch) => {
  const url = `/menu-categories`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      const formattedData = formatMenuAttractions(res.data);

      if (dispatch)
        dispatch(setMenuAttractions({ attractions: formattedData }));
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const formatHomeBanners = (rawData) => {
  return rawData.map((el) => {
    return {
      src: `https://portal.ticketmagic.me/uploads/banners/${el.image}`,
      text: el?.heading,
      btnText: el?.button_title,
      btnLink: el?.button_link,
    };
  });
};
const apiGetHomeBanners = async (dispatch) => {
  const url = `/banners`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      const formattedData = formatHomeBanners(res.data);
      if (dispatch) dispatch(setHomeBanners({ homeBanners: formattedData }));
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetLiveSearch = async (postData) => {
  const url = `/all-attractions?search=${postData.search}`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetAd = async (postData) => {
  const url = `/get-site-ads`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetPromoPopups = async () => {
  const url = `/get-discount-popups`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetSections = async () => {
  const url = `/sections`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetAttractionSearch = async (payload) => {
  const url = `/search`;
  try {
    const res = await axios.post(url, JSON.stringify(payload));
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export {
  apiGetCategories,
  apiGetCategoriesTabs,
  apiGetAttractionEvents,
  apiGetEventDetails,
  apiGetPrioSlots,
  apiGetMenuAttractions,
  apiGetHomeBanners,
  apiGetLiveSearch,
  apiGetAd,
  apiGetPromoPopups,
  apiGetSections,
  apiGetAttractionSearch,
};
