import React, { useRef } from "react";
import MainNav1 from "components/Header/MainNav1";
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";

const active = null;
function B2BContainer({ children }) {
  // const sidebarRef = useRef();
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="w-full flex">
        <div>
          <SideBar
          // ref={sidebarRef}
          />
        </div>
        <div className="w-full">
          <nav className="sticky top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-2 py-3 lg:px-2 lg:pl-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-start"></div>
                <p>Welcome {user.name}</p>
                <div className="flex items-center">
                  <div className="flex items-center ml-3">
                    <MainNav1 />
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {children}
        </div>
      </div>

      {/* <div>
        <div className="lg:ml-40 border-2 rounded-lg dark:border-gray-700 mt-14">
          {children}
        </div>
      </div> */}
    </>
  );
}

export default B2BContainer;
