import React, { useMemo } from "react";

const RecursiveContainer = ({ config, handleChange }) => {
  const builder = (individualConfig, i) => {
    switch (individualConfig.type.toLowerCase()) {
      case "text":
        const classNames = individualConfig.disabled
          ? "bg-gray-100 dark:bg-gray-700 cursor-not-allowed"
          : "";
        return (
          <div className="mt-2" key={i}>
            <label htmlFor={individualConfig.name}>
              {individualConfig.label}
              {individualConfig.required && (
                <span className="text-red-600">*</span>
              )}
            </label>
            <input
              type="text"
              className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50  dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal ${classNames}`}
              name={individualConfig.name}
              defaultValue={individualConfig.value}
              style={{ ...individualConfig.style }}
              required={individualConfig.required}
              disabled={individualConfig.disabled}
              maxLength={individualConfig?.max_length ?? 255}
            />
          </div>
        );
      case "number":
        return (
          <div className="mt-2" key={i}>
            <label htmlFor={individualConfig.name}>
              {individualConfig.label}{" "}
              {individualConfig.required && (
                <span className="text-red-600">*</span>
              )}
            </label>
            <input
              type="number"
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal "
              name={individualConfig.name}
              defaultValue={individualConfig.value}
              style={{ ...individualConfig.style }}
              required={individualConfig.required}
            />
          </div>
        );
      case "date":
        return (
          <div className="mt-2" key={i}>
            <label htmlFor={individualConfig.name}>
              {individualConfig.label}{" "}
              {individualConfig.required && (
                <span className="text-red-600">*</span>
              )}
            </label>
            <input
              type="date"
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal "
              name={individualConfig.name}
              defaultValue={individualConfig.value}
              style={{ ...individualConfig.style }}
              required={individualConfig.required}
            />
          </div>
        );

      case "text_area":
        return (
          <div className="mt-2" key={i}>
            <label htmlFor={individualConfig.name}>
              {individualConfig.label}{" "}
              {individualConfig.required && (
                <span className="text-red-600">*</span>
              )}
            </label>

            <textarea
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 px-4 py-3 text-sm font-normal "
              name={individualConfig.name}
              defaultValue={individualConfig.value}
              style={{ ...individualConfig.style, resize: "none" }}
              required={individualConfig.required}
            ></textarea>
          </div>
        );
      case "select":
        return (
          <div className="mt-2" key={i}>
            <label htmlFor={individualConfig.name}>
              {individualConfig.label}{" "}
              {individualConfig.required && (
                <span className="text-red-600">*</span>
              )}
            </label>
            <select
              name={individualConfig.name}
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal "
              style={{ ...individualConfig.style }}
              required={individualConfig.required}
              defaultValue={individualConfig.value}
            >
              {/* {Object.map(individualConfig.options, function (v, k) {
                  return <option val={k}>{v}</option>;
                })} */}
              <option value="">Select an option</option>
              {Object.keys(individualConfig.options).map((keyName, i) => {
                return (
                  <option value={`${keyName}`} key={i}>
                    {individualConfig.options[keyName]}
                  </option>
                );
              })}
            </select>
          </div>
        );
      case "hidden":
        return (
          <input
            type="hidden"
            name={individualConfig.name}
            defaultValue={individualConfig.value}
          />
        );
      case "array":
        return <RecursiveContainer config={individualConfig.children || []} />;
      default:
        return <div>Unsupported field</div>;
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {config.map((c, i) => {
          return builder(c, i);
        })}
      </div>
    </>
  );
};

export default RecursiveContainer;
