import axios from "axios";

import config from "../config/config";
// import { toast } from "react-toastify";
import { logoutUser } from "store/reducers/user";
import i18n from "i18n/i18n";

let store;
export const injectStore = (_store) => {
  store = _store;
};

const axiosInterceptor = {
  initialise: () => {
    axios.defaults.timeout = 60000;
    axios.defaults.timeoutErrorMessage =
      "Server is not reachable. Please try later.";
    axios.defaults.baseURL = `${config.apiUrl}`;

    // Request Interceptor. All Request pass from here
    axios.interceptors.request.use(async (axiosConfig) => {
      axiosConfig.baseURL = axiosConfig.baseURL + `/${i18n.language ?? "en"}`;
      // const accessToken = store.getState().user.jwt;

      // if (accessToken) {
      //   console.log(axiosConfig);
      //   axiosConfig.headers["jwt"] = "lskdsdksl";
      // }

      if (axiosConfig?.data instanceof FormData) {
        axiosConfig.headers["Content-Type"] = "multipart/form-data";
      } else if (axiosConfig?.data instanceof Object) {
        axiosConfig.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        // axiosConfig?.data = querystring.stringify(postData)
      }

      if (axiosConfig?.actionMsgs) {
        axiosConfig.payload = axiosConfig.actionMsgs;
      }
      return axiosConfig;
    });

    /*
            Response Interceptor
            Responsibilities:
            1- If api sends new token then change the store auth token with new token
        */

    axios.interceptors.response.use(
      (response) => {
        if (
          response?.data?.isSuccess === true &&
          response?.config?.payload?.success
        ) {
          // store.dispatch(snackbarSuccess({ message: response?.config?.payload?.success }));
        } else if (
          response?.data?.isSuccess === false ||
          response?.data?.success === false
        ) {
          // store.dispatch(snackbarError({ message: response?.data?.message ?? response?.config?.payload?.fail }));
        }
        return response;
      },
      async function (error) {
        // store.dispatch(
        //   snackbarError({
        //     message: `${error?.message}. ${error?.config?.payload?.fail ?? ""}`,
        //   })
        // );

        if (error?.response.status === 403) {
          console.log(error?.response);
          // debugger;
          // toast.error("Session expired. Please try login again.");
          store.dispatch(logoutUser({}));
          window.location = "login";
        }
        return Promise.reject(error);
      }
    );
  },
};

export default axiosInterceptor;
