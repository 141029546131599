import { Navigate, Outlet } from "react-router-dom";
import B2BContainer from "components/TM_B2B/B2BContainer/B2BContainer";

const ProtectedRoute = ({ user }) => {
  if (!user?.isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return <B2BContainer>
      <Outlet/>
  </B2BContainer>;
};

export default ProtectedRoute;
