// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  isLoggedIn: false,
  id: null,
  jwt: null,
  language: "en",
  country: "ae",
  name: "",
  recentVisits: [],
  lastVisitDt: null
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser(state, action) {
      const { id, jwt, name } = action.payload;
      if (id && jwt) {
        return { ...state, isLoggedIn: true, id, jwt, name };
      }
    },
    logoutUser(state, action) {
      if(window && window.location){
        window.location.href = '/login';
      }
      return { ...initialState };
    },
    setLanguage(state, action) {
      return { ...state, language: action.payload?.language };
    },
    updateRecentVisits(state, action) {
      
      const exits = state.recentVisits.find(el=>el.href === action.payload.recentVisit.href);
      if(!exits){
        return { ...state, recentVisits: [action.payload.recentVisit, ...state.recentVisits].slice(0,5) };
      }else{
        return{...state}
      }
    },
    updateLastVisitDt(state, action){
      return{...state, lastVisitDt: new Date().getTime()}
    }
  },
});

export default user.reducer;

export const { loginUser, logoutUser, setLanguage , updateRecentVisits, updateLastVisitDt} = user.actions;
