import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";

import Footer from "shared/Footer/Footer";

import Page404 from "containers/Page404/Page404";
// import useWindowSize from "hooks/useWindowResize";

import FooterNav from "components/FooterNav";

import ProtectedRoute from "./ProtectedRoute";

import { useSelector } from "react-redux";
// import TMSearchPage from "containers/TMSearchPage/SearchPage";

import TMLazyLoader from "components/TMLazyLoader/TMLazyLoader";
import TMCustomersPage from "containers/TMCustomersPage/TMCustomersPage";

const PageLanding = TMLazyLoader(
  lazy(() => import("containers/PageLanding/PageLanding"))
);
const PageHome = TMLazyLoader(
  lazy(() => import("containers/PageHome/PageHome"))
);

const BookingsPage = TMLazyLoader(
  lazy(() => import("containers/TMBookingsPage/TMBookings"))
);

const CustomersPage = TMLazyLoader(
  lazy(() => import("containers/TMCustomersPage/CustomersTable"))
);

const TMListingExperiencesPage = TMLazyLoader(
  lazy(
    () => import("containers/TMListingExperiencesPage/TMListingExperiencesPage")
  )
);
const TMListingExperiencesDetailPage = TMLazyLoader(
  lazy(
    () =>
      import(
        "containers/TMListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage.js"
      )
  )
);
const TMSearchPage = TMLazyLoader(
  lazy(() => import("containers/TMSearchPage/SearchPage"))
);

const TMPageSignUp = TMLazyLoader(
  lazy(() => import("containers/TMPageSignUp/PageSignUp"))
);
const TMPageLogin = TMLazyLoader(
  lazy(() => import("containers/TMPageLogin/PageLogin"))
);

const ForgotPasssword = TMLazyLoader(
  lazy(() => import("containers/TMForgotPassword/ForgotPasssword"))
);
const ResetPassword = TMLazyLoader(
  lazy(() => import("containers/TMResetPassword/ResetPassword"))
);

const ProfilePage = TMLazyLoader(
  lazy(() => import("containers/TMAccountPage/AccountPage"))
);
const TMCartPage = TMLazyLoader(
  lazy(() => import("containers/TMCartPage/TMCartPage"))
);
const CheckOutPage = TMLazyLoader(
  lazy(() => import("containers/TMCheckOutPage/CheckOutPage"))
);
const PayPage = TMLazyLoader(
  lazy(() => import("containers/TMPayPage/PayPage"))
);
const PayFailPage = TMLazyLoader(
  lazy(() => import("containers/TMPayFailPage/TMPayFailPage"))
);

const TMB2BDashboard = TMLazyLoader(
  lazy(() => import("containers/TMB2B/Dashboard/Dashboard"))
);

const Reports = <></>;

export const pages: Page[] = [
  { path: "/", component: PageLanding },

  { path: "/payment-success", component: PayPage },
  { path: "/payment-failure", component: PayFailPage },
  { path: "/signup", component: TMPageSignUp },
  { path: "/login", component: TMPageLogin },
];

export const protectedPages: Page[] = [
  { path: "/dashboard", component: TMB2BDashboard },

  { path: "/checkout", component: CheckOutPage },
  { path: "/profile", component: ProfilePage },
  { path: "/bookings", component: BookingsPage },

  { path: "/search", component: TMSearchPage },
  { path: "/dashboard", component: TMB2BDashboard },
  { path: "/reports", component: TMB2BDashboard },
  {
    path: "/cart",
    component: TMCartPage,
  },
  { path: "/attractions", exact: true, component: PageHome },

  {
    path: "/category/:id",
    component: TMListingExperiencesPage,
  },
  {
    path: "/attraction/:experienceId",
    component: TMListingExperiencesDetailPage,
  },
  {
    path: "/events/category/:id",
    component: TMListingExperiencesPage,
  },
  {
    path: "/event/:experienceId",
    component: TMListingExperiencesDetailPage,
  },
  {
    path: "/customers",
    component: TMCustomersPage,
  },
];

const MyRoutes = () => {
  // let WIN_WIDTH = useWindowSize().width;
  let WIN_WIDTH = 1536;

  if (typeof window !== "undefined") {
    WIN_WIDTH = window.innerWidth;
  }

  const user = useSelector((state: any) => state.user);

  return (
    <>
      {
        <BrowserRouter>
          {/* {renderHalloween()} */}
          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<ProtectedRoute user={user} />}>
              {protectedPages.map(({ component, path }) => {
                const Component = component;
                return <Route key={path} element={<Component />} path={path} />;
              })}
            </Route>

            <Route path="*" element={<Page404 />} />
          </Routes>
          {WIN_WIDTH < 768 && <FooterNav />}
          {/* <Footer /> */}
        </BrowserRouter>
      }
    </>
  );
};

export default MyRoutes;
