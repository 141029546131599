import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  PlusIcon,
  PencilIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import B2BModalAddCustomer from "./B2BModalAddCustomer/B2BModalAddCustomer";
import B2BModalEditCustomer from "./B2BModalEditCustomer/B2BModalEditCustomer";
import { apiGetCustomers } from "apis/b2b";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const { format: dateFormatter } = require("date-fns");

const searchDataInitial = {
  page: 0,
  searchQuery: "",
  limit: 10,
};

function CustomersTable() {
  const addBtnRef = useRef();

  const user = useSelector((state) => state.user);

  const [customers, setCustomers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editId, setEditId] = useState(null);

  const [isEnd, setIsEnd] = useState(false);
  const [searchData, setSearchData] = useState(searchDataInitial);

  const _handleCreation = () => {
    getCustomers();
  };

  const _handleEditClick = (id) => {
    setEditId(id);
  };

  const _handleEdit = () => {
    getCustomers();
  };

  const _handleEditCancel = (id) => {
    setEditId(null);
  };

  const renderTableActions = useMemo(() => {
    return (
      <div className="mt-3 md:flex md:items-center md:justify-between">
        <div className="mt-3 md:flex md:items-center md:justify-between gap-x-3">
          <button
            className="text-center inline-flex items-center  w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-yellow-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-yellow-600 dark:hover:bg-yellow-500 dark:bg-yellow-600"
            ref={addBtnRef}
          >
            <PlusIcon className="w-4 h-4" />
            Add
          </button>
        </div>

        {/* <div className="mt-3 md:flex md:items-center md:justify-between gap-x-3">
          <div className="relative flex items-center mt-4 md:mt-0">
            <span className="absolute">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </span>

            <input
              type="text"
              className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <button className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-yellow-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-yellow-600 dark:hover:bg-yellow-500 dark:bg-yellow-600">
            <span>Search</span>
          </button>
        </div> */}
      </div>
    );
  }, []);

  const renderTableHeader = useMemo(() => {
    return (
      <thead className="bg-gray-50 dark:bg-gray-800">
        <tr>
          <th
            scope="col"
            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            Name
          </th>

          <th
            scope="col"
            className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            Email
          </th>

          <th
            scope="col"
            className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            Contact
          </th>

          <th
            scope="col"
            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            Date added
          </th>

          {/* <th
            scope="col"
            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            Added By
          </th> */}

          <th scope="col" className="relative py-3.5 px-4">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
    );
  }, []);

  const renderLoader = useMemo(() => {
    return (
      <tr>
        <td colSpan="5">
          <div role="status" className="flex justify-center py-2">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </td>
      </tr>
    );
  }, []);

  const renderTableRow = (data, i) => {
    return (
      <tr key={i}>
        <td className="px-4 text-sm font-medium whitespace-nowrap">
          <div>
            <h2 className="font-medium text-gray-800 dark:text-white ">
              {`${data.first_name} ${data.last_name}`}
            </h2>
          </div>
        </td>
        <td className="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <div>{data.email}</div>
        </td>
        <td className="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <div>{data.contact_number}</div>
        </td>
        <td className="px-4 py-2 text-sm text-gray-500  whitespace-nowrap">
          <div>
            {dateFormatter(new Date(`${data.created_at} UTC`), "do MMMM, yyyy")}
            <p>
              {dateFormatter(new Date(`${data.created_at} UTC`), "hh:mm a")}
            </p>
          </div>
        </td>
        {/* <td className="px-4 py-2 text-sm whitespace-nowrap">
          <div className="flex items-center">
            <img
              className="object-cover w-6 h-6 -mx-1 border-2 border-white rounded-full dark:border-gray-700 shrink-0"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80"
              alt=""
            />
            <span className="ml-2">{data?.addedBy?.name}</span>
          </div>
        </td> */}
        <td className="px-4 py-2 text-sm whitespace-nowrap">
          <button
            className="px-1 py-1 text-gray-500 transition-colors  rounded-lg dark:text-gray-300 hover:bg-gray-100"
            onClick={() => _handleEditClick(data.id)}
          >
            <PencilIcon className="w-4 h-4 text-gray-600" />
          </button>
        </td>
      </tr>
    );
  };

  const getCustomers = async () => {
    setIsLoading(true);
    const postData = { token: user.jwt, page: searchData.page };
    const res = await apiGetCustomers(postData);
    setIsLoading(false);

    if (res && res.success === true) {
      // console.log(res.data);
      setCustomers(res.data);
      setIsEnd(res?.data.length < 10);
    } else if (res.success === false) {
      toast.error(res?.message ?? "Error occoured");
    } else {
      toast.error("Error occoured");
    }
  };

  useEffect(() => {
    getCustomers();
  }, [searchData]);

  return (
    <>
      <section className="w-100">
        {renderTableActions}

        <div className="flex flex-col mt-3">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  {renderTableHeader}
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {isLoading === true ? renderLoader : <></>}
                    {customers?.map((data, i) => renderTableRow(data, i))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 sm:flex sm:items-center sm:justify-end ">
          <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
            <button
              disabled={searchData.page === 0 || isLoading}
              onClick={() => {
                setSearchData((prevState) => {
                  return { ...prevState, page: prevState.page - 1 };
                });
              }}
              className={`flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800`}
            >
              <ArrowLeftIcon className="w-5 h-5" />
              <span>previous</span>
            </button>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Page{" "}
              <span className="font-medium text-gray-700 dark:text-gray-100">
                {searchData.page + 1}
              </span>
            </div>
            <button
              className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
              disabled={isEnd || isLoading}
              onClick={() => {
                setSearchData((prevState) => {
                  return { ...prevState, page: prevState.page + 1 };
                });
              }}
            >
              <span>Next</span>
              <ArrowRightIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </section>
      <B2BModalAddCustomer
        addBtnRef={addBtnRef}
        actionPerformed={_handleCreation}
      />

      {editId ? (
        <B2BModalEditCustomer
          id={editId}
          actionPerformed={_handleEdit}
          actionIgnored={_handleEditCancel}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CustomersTable;
